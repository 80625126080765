import React from "react"
import { connect } from "react-redux"
import { addNotification, removeNotification } from "../../redux/actions"
import Notifier from "./Notifier"

function mapDispatchToProps(dispatch) {
  return {
    addNotification: notifications => dispatch(addNotification(notifications)),
    removeNotification: notifications =>
      dispatch(removeNotification(notifications)),
  }
}
const mapStateToProps = state => {
  return { notifications: state.notifications }
}
const ConnectedComponent = ({
  notifications,
  removeNotification,
  addNotification,
}) => (
  <Notifier
    notifications={notifications}
    removeNotification={removeNotification}
    addNotification={addNotification}
  />
)

const ReduxComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedComponent)

export default ReduxComponent
