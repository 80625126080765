// src/js/actions/index.js
// SET_USER
// STORE_MEALPLAN
// SET_MEALPLAN 
// SET_UID
// ADD_NOTIFICATION
// SET_PROFILE_MENU
// SET_MOBILE_MENU
// SET_USER_WEIGHT

import { 
   SET_USER,
   SET_UID,
   SET_RECIPES,
   SET_FIREBASE,
   SET_CALCULATOR,
   SET_NOTIFICATION,
   ADD_NOTIFICATION,
   REMOVE_NOTIFICATION,
   SET_STAR_ADMIN
} from "../../constants/action-types";

export function storeUser(payload) {
  return { type: SET_USER, payload };
}
export function storeFirebase(payload) {
  return { type: SET_FIREBASE, payload };
}
export function storeCalculator(payload) {
  return { type: SET_CALCULATOR, payload };
}
export function storeUserId(payload) {
  return { type: SET_UID, payload };
}
export function storeRecipes(payload) {
  return { type: SET_RECIPES, payload };
}
export function setNotifications(payload) {
  return { type: SET_NOTIFICATION, payload };
}
export function setStarAdmin(payload) {
  return { type: SET_STAR_ADMIN, payload };
}
export function addNotification(payload) {
  return { type: ADD_NOTIFICATION, payload };
}
export function removeNotification(payload) {
  return { type: REMOVE_NOTIFICATION, payload };
}