import React from 'react';
import { Link } from 'gatsby';
import AuthUserContext from '../Authentication/AuthUserContext';
import * as routes from '../../constants/routes';
import firebase from "gatsby-plugin-firebase"
const SignOut = () =>{
  firebase.auth().signOut()
}
const NavigationAuth = () =>
  <ul>
    <li><Link to={routes.HOME}>Home</Link></li>
    <li><Link to={routes.GETSTARTED}>Get Started</Link></li>
    <li><Link to={routes.RECIPES}>Recipes</Link></li>
    <li><Link to={routes.BLOG}>Blog</Link></li>
    <li><Link to={routes.PROGRAMME}>The Programme</Link></li>
    <li><Link to={routes.TIPS}>Tips</Link></li>
    <li><Link to={routes.KETOCALCULATOR}>Keto Calculator</Link></li>
    <li><Link to={routes.CONTACT}>Contact Us</Link></li>
    <li>
      <button className="cursor-pointer" onClick={SignOut}>Sign Out</button>
    </li>
  </ul>

const NavigationNonAuth = () =>
  <ul>
    <li><Link to={routes.HOME}>Home</Link></li>
    <li><Link to={routes.GETSTARTED}>Get Started</Link></li>
    <li><Link to={routes.RECIPES}>Recipes</Link></li>
    <li><Link to={routes.BLOG}>Blog</Link></li>
    <li><Link to={routes.PROGRAMME}>The Programme</Link></li>
    <li><Link to={routes.TIPS}>Tips</Link></li>
    <li><Link to={routes.KETOCALCULATOR}>Keto Calculator</Link></li>
    <li><Link to={routes.CONTACT}>Contact Us</Link></li>
    <li><Link to={routes.SIGNUP}>Signup</Link></li>
    <li><Link to={routes.LOGIN}>Login</Link></li>
  </ul>


const Navigation = (props) =>{
  return(
      <div className={"navigationWrapper " + props.color}>
          <AuthUserContext.Consumer>
            {authUser => authUser 
              ? <NavigationAuth />
              : <NavigationNonAuth />
            }
          </AuthUserContext.Consumer>
      </div> 
  )
}

export default Navigation;