import React, { Component } from 'react';
import MaleAvatar from '../../Content/images/Dashboard/MaleAvatar_sm.png';
import FemaleAvatar from '../../Content/images/Dashboard/FemaleAvatar_sm.png';
export default class NavigationAvatar extends Component {
	constructor(props){
		super(props)
		this.state = {
			loading: false,
			width: window.innerWidth,
		};
	}
	componentDidMount() {
		this.handleWindowDimensions()
		window.addEventListener('resize', this.handleWindowDimensions.bind(this))
	}
	handleWindowDimensions(){
		this.setState({
			width: window.innerWidth,
		})
	}
	render() {
		const {avatar, avatarLetter, avatarImageUrl, stats} = this.props.user;
		let showProfileCaret = this.state.width < 1000;
		return (
			<div className="navigationAvatarWrapper mt-2 ">
				{avatarImageUrl?
					<>
						<div style={{
							backgroundImage: 'url(' + this.props.user.avatarImageUrl + ')',
							backgroundSize: 'cover',
							backgroundPosition: 'top center',
							width: '50px',
							height: '50px',
							borderRadius: '50%',
							overflow: 'hidden',
							backgroundColor: '#f15b29',		
						}}></div>
					</>
				:
				<div>
					{stats?
						<SexAvatar sex={this.props.user.stats.sex}/>
						:
						<>
							{avatar?
								<img src={this.props.user.avatar} alt="user avatar" className="avatarCharacterSphere"/>
							:
								<>{avatarLetter?
										<div className="avatarCharacterSphere">{this.props.user.avatarLetter}</div>
									:null}
								</>
							}
						</>
					}
				</div>
				}
				<div style={{color: 'white', marginLeft: '20px', fontSize: '18px'}} className="userEmail">
					{this.props.user.email}
					
				</div>
				{showProfileCaret&&<div className="ml-auto"><span className="flaticon-drop-down-arrow text-white"></span></div>}
			</div>
		);
	}
}
export class SexAvatar extends React.Component {
	render() {
		let female = this.props.sex === 'Female';
		let male = this.props.sex === 'Male';
		return (
			<>
			{female?
				<div style={{
					backgroundImage: 'url(' + FemaleAvatar + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
					width: '50px',
					height: '50px',
					borderRadius: '50%',
					overflow: 'hidden',
					backgroundColor: '#f15b29',		

				}}>
				</div>
				:
				null
			}
			{male?
				<div style={{
					backgroundImage: 'url(' + MaleAvatar + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
					width: '50px',
					height: '50px',
					borderRadius: '50%',
					overflow: 'hidden',
					backgroundColor: '#f15b29',		
				}}>
				</div>
				:
				null
			}
			</>
		);
	}
}
