import React from "react"
import { Link } from "gatsby"
import HeaderNavigation from "./HeaderNavigation.js"
import { connect } from "react-redux"
import {
  storeUser,
  storeUserId,
  storeRecipes,
  addNotification,
  setStarAdmin,
} from "../../redux/actions"
function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    storeRecipes: recipes => dispatch(storeRecipes(recipes)),
    storeUserId: id => dispatch(storeUserId(id)),
    addNotification: notification => dispatch(addNotification(notification)),
    setStarAdmin: admin => dispatch(setStarAdmin(admin)),
  }
}
const mapStateToProps = state => {
  return { user: state.user, uid: state.uid }
}
const HeaderConnect = ({ user, uid, storeUser, storeUserId }) => (
  <Header
    user={user}
    uid={uid}
    storeUserId={storeUserId}
    storeUser={storeUser}
  />
)
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navigationStatus: "disabled",
    }
  }

  handleMobileMenu(e) {
    if (this.state.navigationStatus === "active") {
      this.setState({ navigationStatus: "disabled" })
    } else {
      this.setState({ navigationStatus: "active" })
    }
  }
  render() {
    return (
      <div className="clearfix w-full bg-white block header">
        <div className="pageGrid" style={{ padding: 0 }}>
          <Link to="/" className="logo">
            <h2 className="logoTextHeading largeTitle text-primary">
              Alpha Lipid<sup>TM</sup>{" "}
              <strong>
                {" "}
                SDII<sup>TM</sup>
              </strong>
            </h2>
          </Link>
          <nav className={"mainNav " + this.state.navigationStatus}>
            <button
              onClick={this.handleMobileMenu.bind(this)}
              className={"mobileButtonTrigger " + this.state.navigationStatus}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <HeaderNavigation
              color={this.props.navColor}
              uid={this.props.uid}
              user={this.props.user}
              handleLink={this.handleMobileMenu.bind(this)}
            />
          </nav>
        </div>
      </div>
    )
  }
}
const HeaderConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderConnect)

export default HeaderConnected
