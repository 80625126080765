export const HOME = '/';
export const CACHE = '/cache-test';
export const CONTACT = '/contact';
export const GETSTARTED = '/get-started';
export const THESCIENCE = '/the-science';
export const RECIPES = '/recipes';
export const SINGLERECIPE = '/recipes/:id';
export const BLOG = '/blog';
export const PRODUCTS = '/products';
export const FORGOTPASSWORD = '/forgot-password';
export const SINGLEPRODUCT = '/products/:id';
export const BLOGPOST = '/blog/:id';
export const PROGRAMME = '/programme';
export const FREEMEALPLANS = '/programme/meal-plans';
export const ABOUTPROGRAMME = '/about-the-programme';
export const KETOCALCULATOR = '/keto-calculator'
export const TIPS = '/tips';
export const SIGNUP = '/signup';
export const TELLUSABOUTYOURSELF = '/profile/tell-us-about-yourself';
export const FREETRIAL = '/free-trial';
export const LOGIN = '/login';
export const ERROR404 = '/404';
export const FAQ = '/frequently-asked-questions';
export const PROFILE = '/profile';
export const USERMEALPLANS = '/profile/meal-plans';
export const PROGRESSTRACKER = '/profile/progress-tracker'
export const USERSHOPPINGLIST = '/profile/shopping-list';
export const DAILYJOURNAL = '/profile/daily-journal';
export const FAVORITERECIPES = '/profile/favorite-recipes';
export const BODYMEASUREMENTSPAGE = '/profile/body-measurements';
export const FACEBOOKPRIVACYPOLICY = '/policy/facebook-app-privacy-policy';
export const RECIPECOLLECTIONS = '/recipes/recipe-collections'
export const STARCHALLENGE = '/challenges/stars-of-alsdii'