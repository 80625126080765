// src/js/constants/action-types.js

export const SET_COUNTRY = "SET_COUNTRY";
export const HANDLE_CART = "HANDLE_CART";
export const SET_USER = "SET_USER";
export const SET_CALCULATOR = "SET_CALCULATOR";
export const SET_RECIPES = "SET_RECIPES";
export const STORE_CARTINDEX = "STORE_CARTINDEX";
export const SET_UID = "SET_UID";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SET_STAR_ADMIN = "SET_STAR_ADMIN";
export const SET_FIREBASE = "SET_FIREBASE";