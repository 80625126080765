import React from 'react';
import FooterNavigation from './FooterNavigation'


const Footer = () => {
	return (
		<div className="footer">
			<div className="orangeFooterBar"></div>
			<div className="pageGrid">
				<div className="footerNavigationWrapper pb-10">
					<FooterNavigation/>
				</div>
			</div>
		</div>
	)
}

export default Footer;