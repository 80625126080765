import firebase from 'gatsby-plugin-firebase';
export const storeUserAvatar = (id, file) =>
firebase.storage().ref(`images/user/${id}`).put(file).then((snapshot)=>{
		let url = snapshot.ref.getDownloadURL(); 
		return (url)
})

export const storeProgressImage = (id, picture, name) =>
firebase.storage().ref(`progressTracker/${id}/${name}`).put(picture).then((snapshot)=>{
		let url = snapshot.ref.getDownloadURL(); 
		return (url)

})

