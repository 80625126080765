import {createStore as reduxCreateStore} from "redux"
import {
  SET_UID,
  SET_USER,
  SET_RECIPES,
  SET_FIREBASE,
  SET_CALCULATOR,
  SET_STAR_ADMIN,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,

} from "../../constants/action-types";

function reducer(state = initialState, action) {
  if (action.type === SET_UID) {
    return Object.assign({}, state, {uid: action.payload});
  }
  if (action.type === SET_USER) {
    return Object.assign({}, state, {user: action.payload});
  }
  if (action.type === SET_RECIPES) {
    return Object.assign({}, state, {recipes: action.payload});
  }
  if (action.type === SET_FIREBASE) {
    return Object.assign({}, state, {firebase: action.payload});
  }
  if (action.type === SET_STAR_ADMIN) {
    return Object.assign({}, state, {starAdmin: action.payload});
  }
  if (action.type === SET_CALCULATOR) {
    return Object.assign({}, state, {calculator: action.payload});
  }
  if (action.type === ADD_NOTIFICATION) {
    return Object.assign({}, state, {
      notifications: state
        .notifications
        .concat(action.payload)

    });
  }
  if (action.type === REMOVE_NOTIFICATION) {
    return Object.assign({}, state, {
      notifications: [
        ...state
          .notifications
          .slice(0, action.payload),
        ...state
          .notifications
          .slice(action.payload + 1)
      ]
    });
  }
  return state;
}
const initialState = {

  user: {
    Active_Meal_Plan: [],
    Custom_Recipes: {},
    Favorite_Recipes: {},
    Journal: {},
    Meal_Plans: {},
    Progress_Tracker: {},
    Shopping_List: {},
    avatarImageUrl: "",
    avatarLetter: "",
    country: {
      label: '',
      value: ''
    },
    email: '',
    fresh: false,
    marketing: "true",
    preferences: {
      imperialMeasurements: true,
      marketing: false,
      mealPlan: {
        preferSnacks: true
      }
    },
    premium: false,
    stats: {},
    userCode: true,
    username: '',
    verified: false
  },
  recipes: {
    frontmatter: {},
    head: {},
    pages: []
  },
  uid: '',
  notifications: [],
  calculator: {},
  starAdmin: false
};
const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
