import firebase from 'gatsby-plugin-firebase';
// User API
export const doCreateUser = (id, email, username, marketing, country, verified, userCode, avatarLetter, trial) =>
  firebase.database().ref(`users/${id}`).set({
    email,
    username, 
    marketing, 
    country, 
    verified, 
    userCode,
    avatarLetter,
    fresh: true,
    skipTour: false,
    trial,

  });
  export const storeSocialLoginFromTrial = (user, provider) => {
  firebase.database().ref(`users/${user.uid}`).update({
    username: user.displayName,
    email: user.email,
    avatar: user.photoURL,
    emailVerified: user.emailVerified,
    verified: user.verified,
    provider: provider,
    trial: user.trial,
    premium: user.premium,
    userCode: user.userCode,
    trialEnd: user.trialEnd,
    fresh: true,
    skipTour: false,

  })
}
  export const doCreateUserWithTrial = (id, user) => {
  firebase.database().ref(`users/${id}`).set({...user});

}
export const handleBodyMeasurementsLastUpdate = (id, weight, abdomen, hips, thigh, timestamp) =>
  firebase.database().ref(`users/${id}/stats/Body_Measurements`).set({
    lastUpdate: timestamp,
    measurements: {
      weight,
      abdomen,
      hips,
      thigh,
    }
  })

export const updateUserStatsFromProgressTracker = (uid, statsData, day, month, year)=>

firebase.database().ref(`users/${uid}`).update({
    stats: statsData,

})
export const storeuploadedAvatar = (uid, imgUrl)=>
firebase.database().ref(`users/${uid}`).update({
    avatarImageUrl: imgUrl,
})

export const updateProgressFromProgressTracker = (uid, statsData, day, month, year)=>
  firebase.database().ref(`users/${uid}/Progress_Tracker/${year}/${month}`).update({
      [day]: statsData,

  })
export const handleSaveBodyMeasurements = (uid, measurements, year, month, day)=>
  firebase.database().ref(`users/${uid}/Body_Measurements/${year}/${month}/${day}`).update({
      measurements,

  })
//store custom recie 



export const makeMealPlanActive = (uid, Active_Meal_Plan)=>
  firebase.database().ref(`users/${uid}/`).update({
      Active_Meal_Plan,

  })
export const storeCustomRecipe = (uid, recipe)=>
  firebase.database().ref(`users/${uid}/Custom_Recipes/${recipe.name}`).set({
    title: recipe.name,
    name: recipe.name,
    totalFat: recipe.fat,
    totalProtein: recipe.protein,
    totalCarbs: recipe.carbs,
    ingredients: recipe.ingredients,
    recipe: {
    	title: recipe.name,
		totalFat: recipe.fat,
	    totalProtein: recipe.protein,
	    totalCarbs: recipe.carbs,
	    ingredients_parts: [{
	    	ingredients: recipe.ingredients,
	    }]
    }
  })

export const handleSaveJournal = (uid, journalEntry, day, month, year)=>
  firebase.database().ref(`users/${uid}/Journal/${year}/${month}/${day}`).update({
      journal: journalEntry,
  })

// Saves stats from Keto Calculator
export const storeProgressImageUrls = (uid, images, year, month, day) =>
  firebase.database().ref(`users/${uid}/Progress_Tracker/${year}/${month}/${day}`).update({
    images
  })
export const saveUserStats = (uid, statsData, day, month, year, timestamp, date, chartDate) =>
  firebase.database().ref(`users/${uid}/stats`).update({
    lastUpdate: [day, month, year],
    age: statsData.age,
    activityLevel: statsData.activityLevel,
    bmr: statsData.bmr,
    height: statsData.height, // cm
    weight: statsData.weight, // kg
    dailyCarbCalories: statsData.carbs,
    dailyCarbGrams: statsData.carbs/4,
    dailyFatCalories: statsData.fat,
    dailyFatGrams: statsData.fat/9,
    dailyProteinCalories: statsData.protein,
    dailyProteinGrams: statsData.protein/4,
    dailyTotalCalories: statsData.suggestedCalories,
    restingEnergyExpendature: statsData.restingEnergyExpendature,
    sex: statsData.sex,
    weightManagementGoal: statsData.goal,
    timestamp,
    date,
    chartDate,

  }).then(()=>{
    firebase.database().ref(`users/${uid}`).update({
        fresh: false,
    })
  })
export const saveUserStatsBodyMeasurements = (uid, statsData, day, month, year, timestamp, date, chartDate) =>
  firebase.database().ref(`users/${uid}/stats`).update({
    lastUpdate: [day, month, year],
    age: statsData.age,
    activityLevel: statsData.activityLevel,
    bmr: statsData.bmr,
    height: statsData.height, // cm
    weight: statsData.weight, // kg
    dailyCarbCalories: statsData.carbs,
    dailyCarbGrams: statsData.carbs/4,
    dailyFatCalories: statsData.fat,
    dailyFatGrams: statsData.fat/9,
    dailyProteinCalories: statsData.protein,
    dailyProteinGrams: statsData.protein/4,
    dailyTotalCalories: statsData.suggestedCalories,
    restingEnergyExpendature: statsData.restingEnergyExpendature,
    sex: statsData.sex,
    weightManagementGoal: statsData.goal,
    waist: statsData.waistCm,
    abdomen: statsData.abdomenCm,
    thigh: statsData.thighCm,
    waistInch: statsData.waistInch,
    abdomenInch: statsData.abdomenInch,
    thighInch: statsData.thighInch,
    timestamp,
    date,
    chartDate,

  }).then(()=>{
    firebase.database().ref(`users/${uid}`).update({
        fresh: false,
    })
  })

export const saveUserProgress = (uid, statsData, day, month, year, timestamp, date, chartDate) =>
  firebase.database().ref(`users/${uid}/Progress_Tracker/${year}/${month}/${day}`).set({
    lastUpdate: [day, month, year],
    age: statsData.age,
    activityLevel: statsData.activityLevel,
    bmr: statsData.bmr,
    height: statsData.height, // cm
    weight: statsData.weight, // kg
    dailyCarbCalories: statsData.carbs,
    dailyCarbGrams: statsData.carbs/4,
    dailyFatCalories: statsData.fat,
    dailyFatGrams: statsData.fat/9,
    dailyProteinCalories: statsData.protein,
    dailyProteinGrams: statsData.protein/4,
    dailyTotalCalories: statsData.suggestedCalories,
    restingEnergyExpendature: statsData.restingEnergyExpendature,
    sex: statsData.sex,
    weightManagementGoal: statsData.goal,
    timestamp,
    date,
    chartDate,


  })

export const saveUserProgressBodyMeasurements = (uid, statsData, day, month, year, timestamp, date, chartDate) =>
  firebase.database().ref(`users/${uid}/Progress_Tracker/${year}/${month}/${day}`).set({
    lastUpdate: [day, month, year],
    age: statsData.age,
    activityLevel: statsData.activityLevel,
    bmr: statsData.bmr,
    height: statsData.height, // cm
    weight: statsData.weight, // kg
    dailyCarbCalories: statsData.carbs,
    dailyCarbGrams: statsData.carbs/4,
    dailyFatCalories: statsData.fat,
    dailyFatGrams: statsData.fat/9,
    dailyProteinCalories: statsData.protein,
    dailyProteinGrams: statsData.protein/4,
    dailyTotalCalories: statsData.suggestedCalories,
    restingEnergyExpendature: statsData.restingEnergyExpendature,
    sex: statsData.sex,
    weightManagementGoal: statsData.goal,
    timestamp,
    date,
    chartDate,
    waist: statsData.waistCm,
    abdomen: statsData.abdomenCm,
    thigh: statsData.thighCm,
    waistInch: statsData.waistInch,
    abdomenInch: statsData.abdomenInch,
    thighInch: statsData.thighInch,

  })

export const updateUserCountry = (uid, country) =>
  firebase.database().ref(`users/${uid}`).update({
    country
  })

export const handleSnackPreference = (uid, preferSnacks) =>
  firebase.database().ref(`users/${uid}/preferences/mealPlan/`).update({
    preferSnacks
  })

export const handleMeasurementPreference = (uid, imperialMeasurements) =>
  firebase.database().ref(`users/${uid}/preferences`).update({
    imperialMeasurements
  })

export const getUserMealPlans = (uid) =>
   firebase.database().ref(`users/${uid}/Meal_Plans`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((Meal_Plans)=>{
    return Meal_Plans

});

export const savePlanState = (uid, plan, name, pictures) =>
   firebase.database().ref(`users/${uid}/Meal_Plans/${name}`).set({
    plan

  })

export const saveShoppingList = (uid, Shopping_List) =>
   firebase.database().ref(`users/${uid}`).update({
    Shopping_List,

  })

export const resetShoppingList = (uid, Shopping_List) =>
   firebase.database().ref(`users/${uid}/Shopping_List`).remove

   export const getUserShoppingList = (uid) =>
   firebase.database().ref(`users/${uid}/Shopping_List`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((shoppingList)=>{
    return shoppingList

  });

export const SaveIngredientsToShoppingList = (uid, Shopping_List) =>
    firebase.database().ref(`users/${uid}`).update({
      Shopping_List
    })

export const getUserFavoriteRecipes = (uid) =>
   firebase.database().ref(`users/${uid}/Favorite_Recipes`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((recipe)=>{
    return recipe

  });

export const addFavoriteRecipe = (uid, urlId) =>
  firebase.database().ref(`users/${uid}/Favorite_Recipes/${urlId}`).set({
    isFavorite: true, 
    urlId: urlId,

  })
export const unFavoriteRecipe = (uid, title) => 
 firebase.database().ref(`users/${uid}/Favorite_Recipes/${title}`).remove()

export const checkIfFavoriteRecipe = (uid, link) =>
  firebase.database().ref(`users/${uid}/Favorite_Recipes/${link}`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((user)=>{
    if(user){
      return true;
    }
    else {
      return false;
    }
  });

 
export const addToMealPlans=(uid, recipe, picture, urlId, mealPlan,) =>
  firebase.database().ref(`users/${uid}/Meal_plans/mealPlan${mealPlan}`).set({
    title: recipe.title,
    recipe: recipe,
    picture: picture,
    isFavorite: true, 
    urlId: urlId

  })

  


export const startInitMealPlan=(uid, count, plan) =>
  firebase.database().ref(`users/${uid}/Meal_Plans/${count}`).set({
    plan

  })

export const getFavorite = (id) => 
  firebase.database().ref(`users/${id}`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((user)=>{
    return user;
  });






 
export const updateUserCode = (uid, userCode) =>
  firebase.database().ref(`users/${uid}`).update({
    userCode,
    verified: true,
  })
  
export const verifyUserCode = (uid, verified, code) =>
    firebase.database().ref(`users/${uid}`).update({
      uid,
      verified,
      code
    })

export const storeUserFromFacebookLogin = (user) =>
  
    firebase.database().ref(`users/${user.uid}`).update({
      username: user.displayName,
      email: user.email,
      avatar: user.photoURL,
      emailVerified: user.emailVerified,
      provider: 'Facebook',
      fresh: true,
      skipTour: false,
      
    })

export const storeUserFromGoogleLogin = (user) =>

    firebase.database().ref(`users/${user.uid}`).update({
      username: user.displayName,
      email: user.email,
      avatar: user.photoURL,
      emailVerified: user.emailVerified,
      provider: 'Google',
      fresh: true,
      skipTour: false,
      

    })


export const doUpgradeUser = (id, email, token, coupon, premium, paying_customer) =>
firebase.database().ref(`users/${id}`).update({
  token,
  coupon,
  premium,
  paying_customer
})

export const doCreateFreeUser = (id, username, email, date, country, marketing) => 
  firebase.database().ref(`users/${id}`).set({
    username,
    email,
    date,
    country, 
    marketing
  });


export const getFavorites = (id) => 
  firebase.database().ref(`users/${id}`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((user)=>{
    return user;
  });

// get user object
export const getUserInfo = (id) => 
  firebase.database().ref(`users/${id}`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((user)=>{
    return user;
  });

// get used vouchers 
export const getUsedVoucherCodes = () => 
  firebase.database().ref(`data/usedVouchers`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((codes)=>{
    return codes;
  });
// store used codes to verify repeat uses


export const storeUsedCodes = (usedVouchers) => 
  firebase.database().ref(`data/`).set({
      usedVouchers
  })
// skip the welcome tour 

export const skipUserTour = (id) => 
  firebase.database().ref(`users/${id}`).update({
      skipTour: true,
      fresh: false,
  })

export const storeEmailMessage = (timestamp, message) => 
  firebase.database().ref(`emails/${timestamp}`).set({
      message
  })

export const doHandleUserCount = () => 
firebase.database().ref("analytics/user_count").transaction(function (current_value) {
  return (current_value || 0) + 1;
}); 


// sign up to a challenge 
export const signUpToChallenge = (uid, challenge) => 
  firebase.database().ref(`users/${uid}`).update({
      challenge: challenge,
  })

  export const getChallengeCompetitors = (comp) => 
  firebase.database().ref(`data/challenges/${comp}`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((comp)=>{
    return comp;
  });
  export const getAllUsers = (comp) => 
  firebase.database().ref(`users/`)
  .once(`value`)
  .then(snapshot => snapshot.val()).then((users)=>{
    return users;
  });
  export const saveChallengeCompetitors = (comp, users) => 
  firebase.database().ref(`data/challenges/${comp}`).set({
      users
  })

  export const cacheContent = (cache) =>
  firebase.database().ref(`data/store`).set({
    cache
  })
  
export const createTrialPeriod = (uid, date)=>
  firebase.database().ref(`users/${uid}`).update({
    trial: true,
    trialEnd: date,
  })

export const closePremiumFeatures = (uid)=>
firebase.database().ref(`users/${uid}`).update({
  verified: null,
  trial: false,
})
export const storeTrialUserData = (uid, user)=>
firebase.database().ref(`users/${uid}`).update({
  ...user
})