import firebase from 'gatsby-plugin-firebase';


class Firebase {
  constructor(app) {
    this.auth = firebase.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }
// Sign in with Facebook
}
export const onAuthStateChanged = (state) =>
  firebase.auth().onAuthStateChanged(state);

export const doSigninWithFacebook = () =>
	
	firebase.auth().signInWithPopup(Firebase.facebookProvider).then(function(result) {
	  // This gives you a Facebook Access Token. You can use it to access the Facebook API.
	  // The signed-in user info.
	  const user = result.user;
    return user;
	  // ...
	}).catch(function(error) {
	  // Handle Errors here.
    console.log(error, 'error')
    return error
	});
// sign in with google
export const doSigninWithGoogle = () =>
firebase.auth().signInWithPopup(Firebase.googleProvider).then(function(result) {
  // This gives you a Google Access Token. You can use it to access the Google API.
  // The signed-in user info.
  const user = result.user;
  return user;

  // ...
}).catch(function(error) {
});

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () =>
  firebase.auth().signOut();

// Password Reset
export const doPasswordReset = (email) =>
  firebase.auth().sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  firebase.auth().currentUser.updatePassword(password);

export const getUserProfile = () => 
firebase.auth().currentUser.then((user)=>{
  return user;
 })







