import React from 'react';
import {Link} from 'gatsby';
import SignOut from '../Authentication/SignOut';
import * as routes from '../../constants/routes';
import NavigationAvatar from './NavigationAvatar.js';
export default class ProfileDropDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 'notActive',
			user: this.props.user,
			userLoaded: 'false',
			profileNavStatus: 'closed',
		};
		this.timer = null;
	}
	handleProfileMobileSubMenu(){
		if(this.state.profileNavStatus === 'closed'){
			this.setState({profileNavStatus: 'open',})
		}
		else{
			this.setState({profileNavStatus: 'closed'})
		}
	}
	handleClickDropDown(){
		if(this.state.status === 'notActive' && window.innerWidth > 999){
			clearTimeout(this.timer);
			this.setState({status: 'active'})
		}
	}
	handleDropDownWithHover(){
		this.setState({status: 'active'})
		clearTimeout(this.timer);
	}
	handleDropDown(){
		if( window.innerWidth < 1000){
			this.setState({status: 'active',})
		}
	}
	handleLinkClick(){
		if(this.state.status === 'active'){
			this.setState({status: 'notActive'})	
		}
	}
	delayDropDown(){
		this.timer = setTimeout(()=>{
			this.setState({status: 'notActive'})
		},800)
	}
	handleDropDownLeave(){
		this.delayDropDown();
	}
	render() {
			let open = this.state.status === 'active';
			const {user} = this.props;
			return (
				<div 
					className={"navDropDown pl-4 pb-4 " + this.state.status} 
					onMouseLeave={this.handleDropDownLeave.bind(this)} 
					onClick={this.handleClickDropDown.bind(this)} 
					onMouseEnter={this.handleDropDownWithHover.bind(this)}
				>
					<div
						className="cursor-pointer profileDropDownLink w-full" 
						onClick={this.handleDropDown.bind(this)}
						onBlur={this.handleDropDown.bind(this)}
					>
						{user?
							<NavigationAvatar user={this.props.user} open={open}/>
						:null}
					</div>
					<div onClick={this.handleProfileMobileSubMenu.bind(this)} className={"profileSubNav w-full block " + this.state.profileNavStatus }>
						<span className="mobileButtonLink">Profile</span>
						<span className="flaticon-right-arrow mobileNavSubMenuIco"></span>
					</div>
			        <ul className={this.state.profileNavStatus + " headerNavigationDropdown shadow-lg " + this.state.status}>
			          	<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.PROFILE}>My Profile</Link></li>
			          	<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.USERMEALPLANS}>My Meal Plans</Link></li>
			          	<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.PROGRESSTRACKER}>Progress Tracker</Link></li>
						<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.FAVORITERECIPES}>Favourite Recipes</Link></li>
						<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.USERSHOPPINGLIST}>My Shopping List</Link></li>
						<li><Link onClick={this.handleLinkClick.bind(this)} to={routes.DAILYJOURNAL}>Journal</Link></li>
						<hr className="my-2"/>
			          	<li className="signOutButtonWrapper">
							<SignOut/>
						</li>
			        </ul>
			    </div>
			);
	}


		
}
