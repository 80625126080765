import React, {Fragment} from 'react'
import '../../content/css/tailwind/tailwind.css';
import '../../content/css/masterStyle.css';
import '../../content/fonts/icons/flaticon.css'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
export default function Layout({children}){
    return(
        <Fragment>
            <Header/>
            {children}
            <Footer/>
        </Fragment>
    )
}