// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-about-the-programme-index-js": () => import("./../../../src/Components/AboutTheProgramme/index.js" /* webpackChunkName: "component---src-components-about-the-programme-index-js" */),
  "component---src-components-authentication-login-js": () => import("./../../../src/Components/Authentication/Login.js" /* webpackChunkName: "component---src-components-authentication-login-js" */),
  "component---src-components-authentication-password-forget-js": () => import("./../../../src/Components/Authentication/PasswordForget.js" /* webpackChunkName: "component---src-components-authentication-password-forget-js" */),
  "component---src-components-authentication-sign-up-js": () => import("./../../../src/Components/Authentication/SignUp.js" /* webpackChunkName: "component---src-components-authentication-sign-up-js" */),
  "component---src-components-authentication-trial-page-js": () => import("./../../../src/Components/Authentication/TrialPage.js" /* webpackChunkName: "component---src-components-authentication-trial-page-js" */),
  "component---src-components-blog-article-page-js": () => import("./../../../src/Components/Blog/ArticlePage.js" /* webpackChunkName: "component---src-components-blog-article-page-js" */),
  "component---src-components-blog-blog-page-js": () => import("./../../../src/Components/Blog/BlogPage.js" /* webpackChunkName: "component---src-components-blog-blog-page-js" */),
  "component---src-components-challenges-star-js": () => import("./../../../src/Components/Challenges/Star.js" /* webpackChunkName: "component---src-components-challenges-star-js" */),
  "component---src-components-contact-index-js": () => import("./../../../src/Components/Contact/index.js" /* webpackChunkName: "component---src-components-contact-index-js" */),
  "component---src-components-faq-index-js": () => import("./../../../src/Components/Faq/index.js" /* webpackChunkName: "component---src-components-faq-index-js" */),
  "component---src-components-free-meal-plans-index-js": () => import("./../../../src/Components/FreeMealPlans/index.js" /* webpackChunkName: "component---src-components-free-meal-plans-index-js" */),
  "component---src-components-get-started-get-started-page-js": () => import("./../../../src/Components/GetStarted/GetStartedPage.js" /* webpackChunkName: "component---src-components-get-started-get-started-page-js" */),
  "component---src-components-home-home-js": () => import("./../../../src/Components/Home/Home.js" /* webpackChunkName: "component---src-components-home-home-js" */),
  "component---src-components-ketodux-calculator-keto-calculator-js": () => import("./../../../src/Components/KetoduxCalculator/KetoCalculator.js" /* webpackChunkName: "component---src-components-ketodux-calculator-keto-calculator-js" */),
  "component---src-components-policies-privacy-js": () => import("./../../../src/Components/Policies/Privacy.js" /* webpackChunkName: "component---src-components-policies-privacy-js" */),
  "component---src-components-products-products-page-js": () => import("./../../../src/Components/Products/ProductsPage.js" /* webpackChunkName: "component---src-components-products-products-page-js" */),
  "component---src-components-products-single-product-page-js": () => import("./../../../src/Components/Products/SingleProductPage.js" /* webpackChunkName: "component---src-components-products-single-product-page-js" */),
  "component---src-components-programme-programme-page-js": () => import("./../../../src/Components/Programme/ProgrammePage.js" /* webpackChunkName: "component---src-components-programme-programme-page-js" */),
  "component---src-components-recipe-collections-index-js": () => import("./../../../src/Components/RecipeCollections/index.js" /* webpackChunkName: "component---src-components-recipe-collections-index-js" */),
  "component---src-components-recipe-collections-recipe-collection-js": () => import("./../../../src/Components/RecipeCollections/RecipeCollection.js" /* webpackChunkName: "component---src-components-recipe-collections-recipe-collection-js" */),
  "component---src-components-recipes-recipes-page-js": () => import("./../../../src/Components/Recipes/RecipesPage.js" /* webpackChunkName: "component---src-components-recipes-recipes-page-js" */),
  "component---src-components-recipes-single-recipe-page-js": () => import("./../../../src/Components/Recipes/SingleRecipePage.js" /* webpackChunkName: "component---src-components-recipes-single-recipe-page-js" */),
  "component---src-components-the-science-index-js": () => import("./../../../src/Components/TheScience/index.js" /* webpackChunkName: "component---src-components-the-science-index-js" */),
  "component---src-components-tips-index-js": () => import("./../../../src/Components/Tips/index.js" /* webpackChunkName: "component---src-components-tips-index-js" */),
  "component---src-components-videos-videos-page-js": () => import("./../../../src/Components/Videos/VideosPage.js" /* webpackChunkName: "component---src-components-videos-videos-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenge-admin-js": () => import("./../../../src/pages/challenge-admin.js" /* webpackChunkName: "component---src-pages-challenge-admin-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

