import React from 'react';
import firebase from "gatsby-plugin-firebase"

export default class SignOut extends React.Component {
  signOut() {
    firebase
      .auth()
      .signOut()
  }
  render() {
    return (
      <button
        onClick={this
        .signOut
        .bind(this)}
        className="bg-white mx-0 w-full rounded-lg bg-primary text-white font-medium">
        <span className="flaticon-logout-2">
          &emsp;</span>
        Sign Out
      </button>
    )
  }
}
