import React, { Component } from 'react';
import Html from 'react-html-parser';
export default class Notifier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 700,
        }
    }
    handleWindowDimensions(){
        this.setState({width: window.innerWidth})
    }
    componentDidMount() {
        this.handleWindowDimensions();
        window.addEventListener('resize', this.handleWindowDimensions.bind(this));
    }
    removeNotification(key){
        this.props.removeNotification(key)
    }
    render() {
        const desktop = this.state.width > 639;
        const notifications = this.props.notifications.map((item, key)=>
            <Notification message={item} removeNotification={this.removeNotification.bind(this, key)} key={key} desktop={desktop} reCheckDimensions={this.handleWindowDimensions.bind(this)}/>
        )
        const hasNotifications = this.props.notifications.length > 0;
        return (
            <div className={desktop?"fixed z-50 max-w-sm right-0 py-2 mt-10 mr-2":"bottom-0 fixed z-50 w-full right-0 shadow-lg bg-primary"}>
                {hasNotifications && <div className="py-4">{notifications}</div>}
            </div>
        )
    }
}

class Notification extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
    }
    componentDidMount() {
        this.props.reCheckDimensions();
        this.timer = setTimeout(this.props.removeNotification,5000)
    }
    hoverToRemainActive(){
        clearTimeout(this.timer);
    }
    removeAfterMouseLeaves(){
        this.timer = setTimeout(this.props.removeNotification,5000)
    }
    render() {
        const desktop = this.props.desktop === true;
        return (
            <div 
                className={desktop?"rounded-lg bg-white p-4 text-sm font-medium shadow-lg my-2 border":"text-sm text-white font-medium rounded-lg bg-primary p-2"}
                onMouseEnter={this.hoverToRemainActive.bind(this)}
                onMouseLeave={this.removeAfterMouseLeaves.bind(this)}
            >
                <div className="flex w-full">
                    <span className="mr-2">{Html(this.props.message)}</span>
                    <button className="cursor-pointer ml-auto flaticon-close" onClick={this.props.removeNotification}>
                        <span className="hidden">close</span>
                    </button>
                </div>
            </div>
        )
    }
}
