import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as routes from '../../constants/routes'
import ProfileDropDown from './ProfileDropDown';

export default class Navigation extends Component {
	constructor(props){
		super(props);
		this.state = {
			user: {}
		};
	}
   
  render() {
	let {email} = this.props.user;
	
    return (

      <div className={"headerNavigationWrapper navigationWrapper " + this.props.color}>
          {email?
          	<div>
		    <ul className="navMenu headerNavMenu">
		      <li><Link onClick={()=>{this.props.handleLink()}} to={routes.HOME}>Home</Link></li>
		      <li className="dropdownContainer dropdownHelper">
		        <Link onClick={()=>{this.props.handleLink()}} to={routes.GETSTARTED}>Get Started</Link>
		          <div className="headerNavigationDropDownWrapper">
			          <ul className="headerNavigationDropDown">
			              <li><Link onClick={()=>{this.props.handleLink()}} to='/programme'>The Programme</Link></li>
			          </ul>
		          </div>
		      </li>
		      <li><Link onClick={()=>{this.props.handleLink()}} to={routes.PRODUCTS}>Products</Link></li>
		      <li className="dropdownContainer dropdownHelper">
		      	<Link onClick={()=>{this.props.handleLink()}} to={routes.RECIPES}>Recipes</Link>
		      	<div className="headerNavigationDropDownWrapper">
			          <ul className="headerNavigationDropDown">
			              <li><Link onClick={()=>{this.props.handleLink()}} to='/recipes/recipe-collections'>Collections</Link></li>
			          </ul>
		          </div>
		      </li>
		      <li className="dropdownContainer dropdownHelper last">
				<Link to="/get-started">Tools</Link>
				<div className="headerNavigationDropDownWrapper">
				<ul className="headerNavigationDropDown">
					<li><Link onClick={()=>{this.props.handleLink()}} to={routes.BLOG}>Blog</Link></li>
					<li><Link onClick={()=>{this.props.handleLink()}} to='/frequently-asked-questions'>FAQ</Link></li>
					<li><Link onClick={()=>{this.props.handleLink()}} to='/tips'>TIPS</Link></li>
					<li><Link onClick={()=>{this.props.handleLink()}} to='/keto-calculator'>Keto Calculator</Link></li>
					<li><Link onClick={()=>{this.props.handleLink()}} to='/the-science'>The Science</Link></li>
					<li><Link onClick={()=>{this.props.handleLink()}} to='/videos'>Videos</Link></li>
				</ul>
				</div>
				</li>
		      <hr className="border-white"/>
		      <div className="profileSubMenu"><ProfileDropDown user={this.props.user}/></div>
		    </ul>
		    
		  </div>
		  :
		  <div>  
		  <ul className="navMenu headerNavMenu">
		    <li><Link onClick={()=>{this.props.handleLink()}} to={routes.HOME}>Home</Link></li>
		    <li><Link onClick={()=>{this.props.handleLink()}} to={routes.GETSTARTED}>Get Started</Link></li>
		    <li><Link onClick={()=>{this.props.handleLink()}} to={routes.PRODUCTS}>Products</Link></li>
		    <li className="dropdownContainer dropdownHelper"><Link onClick={()=>{this.props.handleLink()}} to={routes.RECIPES}>
		    	Recipes</Link>
		    	<div className="headerNavigationDropDownWrapper">
		          <ul className="headerNavigationDropDown">
		              <li><Link onClick={()=>{this.props.handleLink()}} to='/recipes/recipe-collections'>Collections</Link></li>
		          </ul>
	          </div>
		    </li>
		    <li className="dropdownContainer dropdownHelper">
		      <Link to="/get-started">Tools</Link>
		      <div className="headerNavigationDropDownWrapper">
		      <ul className="headerNavigationDropDown">
				  <li><Link onClick={()=>{this.props.handleLink()}} to={routes.BLOG}>Blog</Link></li>
		          <li><Link onClick={()=>{this.props.handleLink()}} to='/frequently-asked-questions'>FAQ</Link></li>
		          <li><Link onClick={()=>{this.props.handleLink()}} to='/tips'>TIPS</Link></li>
				  <li><Link onClick={()=>{this.props.handleLink()}} to='/keto-calculator'>Keto Calculator</Link></li>
				  <li><Link onClick={()=>{this.props.handleLink()}} to='/the-science'>The Science</Link></li>
				  <li><Link onClick={()=>{this.props.handleLink()}} to='/videos'>Videos</Link></li>
		      </ul>
		      </div>
		    </li>
		    
		    <li><Link onClick={()=>{this.props.handleLink()}} to={routes.SIGNUP}>Signup</Link></li>
		    <li className="last"><Link onClick={()=>{this.props.handleLink()}} to={routes.LOGIN}>Login</Link></li>
			<hr className="border-white"/>
		    <div className="profileSubMenu">
				<div className="absolute right-0 mr-2 mt-4 top-0">
				<li className="text-white">
					<Link className="bg-primary py-2 rounded-lg text-white px-2" style={{color: '#fff'}} onClick={()=>{this.props.handleLink()}} to={routes.FREETRIAL}>Free Trial</Link>
				</li>
				</div>
			</div>
		  </ul>
		</div>
          }
      </div> 
    );
  }
}


