import React, { useEffect } from "react"
import { connect } from "react-redux"
import WithAuthentication from "../Components/Authentication/WithAuthentication"
import {
  storeUser,
  storeUserId,
  storeRecipes,
  addNotification,
  setStarAdmin,
  storeFirebase,
} from "../redux/actions/"

import firebase from "gatsby-plugin-firebase"
import { db } from "../firebase/"
import Notifications from "../Components/Notifications/Notifications"
import Layout from "../Components/Layouts"
function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    storeRecipes: recipes => dispatch(storeRecipes(recipes)),
    storeUserId: id => dispatch(storeUserId(id)),
    addNotification: notification => dispatch(addNotification(notification)),
    setStarAdmin: admin => dispatch(setStarAdmin(admin)),
    storeFirebase: firebase => dispatch(storeFirebase(firebase)),
  }
}
const mapStateToProps = state => {
  return { user: state.user, uid: state.uid }
}
function checkTrialPeriod(user, uid) {
  const timestamp = +new Date()
  if (user.trialEnd && user.verified === true) {
    const trialEndTimestamp = +new Date(user.trialEnd)
    if (trialEndTimestamp < timestamp) {
      db.closePremiumFeatures(uid)
        .then(() => {
          let updateUser = { ...user }
          updateUser.verified = false
          this.props.storeUser(updateUser)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
function UserWrapper(props) {
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        props.storeUserId(user.uid)
        db.getUserInfo(user.uid)
          .then(userInfo => {
            props.storeUser(userInfo)
            props.storeUserId(user.uid)
            checkTrialPeriod(user.uid, userInfo)
            if (userInfo.star_admin === true) {
              props.setStarAdmin(userInfo.star_admin)
            }
          })
          .catch(e => console.log(e))
      } else {
        props.storeUserId("")
        props.storeUser({})
      }
    })
  }, [])

  return (
    <Layout>
      <Notifications />
      {props.children}
    </Layout>
  )
}
const UserConnected = connect(mapStateToProps, mapDispatchToProps)(UserWrapper)
export default WithAuthentication(UserConnected)
